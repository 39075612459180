import './index.scss'
import '../../assets/styles/jquery.fullpage.min.css'
// 鍒嗗睆
import '../../assets/scripts/jquery.fullpage.min.js'
import Swiper from 'swiper/swiper-bundle.min.js'
var swiper = new Swiper(".page1_bg .mySwiper", {
    loop:true,
    spaceBetween: 30,
    effect: "fade",
    autoplay: {
        delay: 5000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
    },
    pagination: {
      el: ".page1_bg .swiper-pagination",
      clickable: true,
    },
    fadeEffect: {
        crossFade:true,
    }
  });
$(function(){
	$('#dowebok').fullpage({
		navigation:true,
		verticalCentered:false,
		css3: false,
		scrollingSpeed:800,
		slidesNavigation:false,

		anchors: ['page1', 'page2','page3','page4','page5','page6'],
		menu: '#menu',
		autoScrolling: true,
		//anchors: ['hero', 'services', 'cases', 'news','about','contact','footer']
		
		afterLoad: function(anchorLink, index)
		{
			if(index == 1)
			{
				$(".head li").removeClass("active")
                $("#fp-nav").removeClass("on");
                $(".head").removeClass("head1");
				$(".head li:nth-child(1)").addClass("active")
			}
			if(index == 2)
			{
				$(".head li").removeClass("active")
				$("#fp-nav").addClass("on");
                $(".head").addClass("head1");
				$(".head li:nth-child(2)").addClass("active")
			}
			if(index == 3)
			{		
				$(".head li").removeClass("active")
				$("#fp-nav").removeClass("on");
                $(".head").removeClass("head1");
				$(".head li:nth-child(3)").addClass("active")
			}
			if(index == 4)
			{		
				$(".head li").removeClass("active")
				$("#fp-nav").addClass("on");
                $(".head").addClass("head1");
				$(".head li:nth-child(4)").addClass("active")
			}
			if(index == 5)
			{		
				$(".head li").removeClass("active")
				$("#fp-nav").addClass("on");
                $(".head").addClass("head1");
				$(".head li:nth-child(6)").addClass("active")
			}
			if(index == 6)
			{
				$(".head li").removeClass("active")
				$("#fp-nav").removeClass("on");
                $(".head").removeClass("head1");
				$(".head li:nth-child(7)").addClass("active")
			}
		},
	

	});
	$(window).resize(function(){
        autoScrolling();
    });
	/*鎵嬫満涓婁笉婊氬姩*/
	function autoScrolling(){
        var $ww = $(window).width();
        if($ww < 1024){
            $.fn.fullpage.setAutoScrolling(false);
			$.fn.fullpage.setFitToSection(false);
        } else {
            $.fn.fullpage.setAutoScrolling(true);
        }
    }
	autoScrolling();
});
var swiper1 = new Swiper("#swiper1 .mySwiper", {
	loop:true,
	effect: "fade",
	navigation: {
	  nextEl: "#swiper1 .swiper-button-next",
	  prevEl: "#swiper1 .swiper-button-prev",
	},
	fadeEffect: {
        crossFade:true,
    }
});
$(".swiper-button-next").click(function(){
	$(this).addClass("active");
	$(".swiper-button-prev").removeClass("active")
})
$(".swiper-button-prev").click(function(){
	$(this).addClass("active");
	$(".swiper-button-next").removeClass("active")
})
$(".pro3-nav li").hover(function () {
    let index = $(this).index();
    $(this).addClass("active").siblings().removeClass("active");
    $(".pro3-lunbo .pro3-swiper").eq(index).addClass("active").siblings().removeClass("active");
})


$(".pro3-nav li:first").addClass("active");
$(".pro3-lunbo .pro3-swiper:first").addClass("active");

$(".pro4-nav li").hover(function () {
    let index = $(this).index();
    $(this).addClass("active").siblings().removeClass("active");
    $(".pro4-text .text").eq(index).addClass("active").siblings().removeClass("active");
	$(".pro4-bg img").eq(index).addClass("active").siblings().removeClass("active");
})
$(".pro4-bg img:first").addClass("active");
$(".pro4-text .text:first").addClass("active");
$(".pro4-nav li:first").addClass("active");

if (window.innerWidth >768) {
	$(".pro5-news .news1 .pic a:first").addClass("active");
	$(".pro5-news .pic a:first").addClass("active");
	$(".pro5-news .news1 ul li:first").addClass("active");
	$(".pro5-news .news-nav a:first").addClass("active");
	$(".pro5-news .news:first").addClass("active");
	$(".pro5-news .news-nav a:first").addClass("active");
	$(".news ul li").hover(function () {
		let index = $(this).index();
		$(this).addClass("active").siblings().removeClass("active");
		$(".pro5-news .pic a").eq(index).addClass("active").siblings().removeClass("active");
	})
}